import { Button, Image } from "@global";
import {
  Container,
  ImageContainer,
  ImageWrapper,
  P,
  Separator,
} from "@util/standard";
import { Link, navigate } from "gatsby";
import {
  MOBILE_BREAKPOINT,
  TABLET_BREAKPOINT,
  assets,
  colors,
  colorsRGB,
} from "@util/constants";
import React, { MouseEvent, useState } from "react";
import {
  checkProductExistsInBundle,
  useMaximumBundleQuantity,
  useUpdateProductToBundle,
} from "@util/hooks";

import PreviewAllergens from "./sub/previewAllergens";
import ProductForm from "@components/products/productForm";
import QuantityInput from "./quantityInput";
import { SanityProduct } from "@graphql-types";
import styled from "styled-components";
import { urlForImageRef } from "@lib/sanityClient";
import { useStore } from "@state/store";

interface Props {
  product?: SanityProduct;
  noMargin?: boolean;
  category?: string;
  categoryName?: string;
  isGift?: boolean;
  isCarousel?: boolean;
  marginBottom?: string;
  isFav?: boolean;
  isBundle?: boolean;
  bundleId?: string;
}

const DiscountPrice = styled.span`
  display: inline-block;
  margin-left: 10px;
  opacity: 0.5;
`;

const AllergenContainer = styled(Container)`
  width: 100%;
  position: absolute;
  z-index: 2;
  pointer-events: none;
`;

const ProductLink = styled.div`
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  opacity: 0;
  background: ${colorsRGB.primaryBackground(0.8)};
  transition: opacity 0.3s ease;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: stretch;
  padding: 20px;
  box-sizing: border-box;
  &:hover {
    opacity: 1;
  }
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    padding: 10px;
    button {
      padding: 5px;
      min-width: 100%;
      font-size: 10px;
    }
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    display: none;
  }
`;

const StyledA = styled(Link)`
  white-space: initial;
`;

const StyledProductName = styled(P)`
  color: ${colors.merlin};
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    height: 35px;
  }
`;

const ProductPreview = (props: Props) => {
  const {
    product,
    noMargin,
    category,
    categoryName,
    isGift,
    isCarousel,
    marginBottom,
    isFav,
    isBundle,
    bundleId,
  } = props;
  const price = product?.content?.shopify?.defaultPrice;
  const discountedPrice = product?.content?.shopify?.defaultCompareAtPrice;

  const productIsInBundle = checkProductExistsInBundle(bundleId, product?.id);

  const handleAddToBundle = useUpdateProductToBundle(
    bundleId,
    {
      id: product?.id,
      slug: product?.content?.main?.slug?.current,
      title: product?.content?.main?.title,
      lineId: Math.random().toString(36).substring(7),
    },
    productIsInBundle?.lineId
  );

  const handleQuantityUpdate = (
    id: string,
    quantity: number,
    change: number,
    shouldIncrease?: boolean
  ) => {
    handleAddToBundle(shouldIncrease);
  };

  if (!product || !product.content) {
    return null;
  }
  const main = product.content.main;
  if (!main) return null;

  const handleClick = (e: MouseEvent) => {
    const data = {
      categoryHandle: category ? category : "",
      categoryName: categoryName ? categoryName : "",
      hasCategory: Boolean(category),
    };
    useStore.setState({
      categoryParams: data,
    });
  };

  return (
    <Container
      margin={
        noMargin !== true
          ? "0px 45px 0px 0px"
          : marginBottom
          ? `0 0 ${marginBottom}`
          : "0"
      }
      tabletMargin={
        noMargin !== true
          ? "0px 20px 0px 0px"
          : marginBottom
          ? `0 0 ${marginBottom}`
          : "0"
      }
      display="flex"
      flexDirection="column"
      width={isGift ? "33.333%" : "260px"}
      tabletWidth={isGift ? (isCarousel ? "300px" : "50%") : "150px"}
      mobileWidth={isGift ? (isCarousel ? "200px" : "50%") : "150px"}
    >
      <ImageContainer isGift={isGift}>
        <ImageWrapper isGift={isGift}>
          {main.mainImage?.asset?.gatsbyImageData ? (
            <Image
              imageData={main.mainImage?.asset?.gatsbyImageData}
              altText={main.mainImage?.asset?.altText ?? (main.title as string)}
              width="100%"
              height="100%"
              objectFit={isGift ? "cover" : "contain"}
            />
          ) : (
            main.mainImage?.asset && (
              <Image
                staticImage={
                  urlForImageRef(main.mainImage?.asset).url() as string
                }
                altText={
                  main.mainImage?.asset?.altText ?? (main.title as string)
                }
                width="100%"
                height="100%"
                objectFit={isGift ? "cover" : "contain"}
              />
            )
          )}
        </ImageWrapper>
        <AllergenContainer>
          <PreviewAllergens
            productInfo={main}
            id={product.id}
            isOutOfStock={product?.content?.shopify?.isSoldOut === true}
            isFav={isFav}
          />
        </AllergenContainer>
        <ProductLink>
          {product?.content?.shopify && !isBundle && (
            <ProductForm
              shopifyData={product.content.shopify}
              isButton={true}
            />
          )}

          {product?.content?.shopify &&
            !product?.content?.shopify?.isSoldOut &&
            isBundle && (
              <Button
                text={
                  productIsInBundle?.lineId
                    ? "REMOVE FROM BUNDLE"
                    : "ADD TO BUNDLE"
                }
                onClick={handleAddToBundle}
                width="100%"
                theme="base"
                margin="0 0 15px 0"
              />
            )}
          {/* {product?.content?.shopify?.isSoldOut && (
            <Button
              text="BACK SOON"
              width="100%"
              theme="base"
              disabled
              margin="0 0 15px 0"
            />
          )} */}
          <Button
            text="MORE INFO"
            linkTo={`/shop/${main.slug?.current}${
              bundleId ? `?bundle=${bundleId}` : ""
            }`}
            onClick={handleClick}
            width="100%"
            theme="transparent"
          />
        </ProductLink>
      </ImageContainer>

      <Container display="flex" flexDirection="column">
        <StyledA to={`/shop/${main.slug?.current}`} onClick={handleClick}>
          <StyledProductName
            noMargin
            tabletFontSize={13}
            wordBreak="break-word"
          >
            {main.title}
          </StyledProductName>
        </StyledA>
        <Separator color="merlin" marginBottom={8} height="8px" opacity={0.4} />
        {!productIsInBundle?.id && !bundleId ? (
          <Container justifyContent="space-between">
            <P noMargin tabletFontSize={14} padding="2px">
              {price && <>${price}</>}
              {discountedPrice &&
                price &&
                parseFloat(discountedPrice) > parseFloat(price) && (
                  <DiscountPrice>
                    <s>${discountedPrice}</s>
                  </DiscountPrice>
                )}
            </P>

            <P noMargin tabletFontSize={14} padding="2px">
              {product?.content?.info?.declaredWeight}
            </P>
          </Container>
        ) : (
          <>
            {productIsInBundle?.id && (
              <QuantityInput
                defaultQuantity={1}
                quantityMax={undefined}
                updateLineItemFunc={handleQuantityUpdate}
                isBundleItem
                productId={product.id}
                bundleId={bundleId}
              />
            )}
          </>
        )}
      </Container>
    </Container>
  );
};

export default ProductPreview;
