import React from "react";
import styled from "styled-components";

import {
  colors,
  MOBILE_BREAKPOINT
} from "@util/constants";
import { Container } from "@util/standard";
import { Maybe } from "@graphql-types";

const SalePrice = styled.span`
  color: ${colors.sale};
  font-size: 0.8em;
  span{
    font-size: 0.8em;
    letter-spacing: 1px;
  }
`;

const Price = styled.span`
  color: ${colors.merlin};
  font-size: 22px;
  font-weight: 600;
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: 20px;
  }
`;

interface Props {
  price: string;
  comparePrice?: Maybe<string> | undefined;
}

const ProductPrice = ({ price, comparePrice }: Props) => {

  return (
    <Container flexDirection="column" alignItems="flex-end">
      {comparePrice && parseFloat(comparePrice) > parseFloat(price) &&
        <SalePrice><span>BEFORE:</span> ${comparePrice}</SalePrice>
      }
      <Price>${price}</Price>
    </Container>
  );
};

export default ProductPrice;