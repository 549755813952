import React, { useState, FormEvent, useRef, useCallback } from "react";
import fetch from "unfetch";
import { useLoads } from "react-loads";
import styled from "styled-components";
import { toast } from "react-toastify";

import { Container } from "@util/standard";
import { SanityShopifyProductModule } from "@graphql-types";
import QuantityInput from "@shared/quantityInput";
import ProductPrice from "@shared/productPrice";
import { Button } from "@global";
import { useStore, defaultShopify } from "@state/store";
import { Shopify } from "@state/types";
import { CheckoutLineItemInput } from "@util/types";

const Form = styled.form`
  width: 100%;
`;
interface Props {
  shopifyData: SanityShopifyProductModule;
  isButton?: boolean;
}

const ProductForm = ({ shopifyData, isButton = false }: Props) => {
  const [adding, setAdding] = useState(false);
  const productId = shopifyData.defaultVariant?.variantId;
  const elForm = useRef<HTMLFormElement>(null);
  const { openCartDrawer, shopify } = useStore();
  const outOfStock = shopifyData?.isSoldOut === true;

  if (!productId) return null;

  const handleAddToCart = useCallback(
    async (lineItems: CheckoutLineItemInput, shopifyObj: Shopify) => {
      if (!lineItems) return;
      setAdding(true);

      const data = {
        lines: lineItems,
        cartId: shopifyObj?.cart?.id,
        customer: shopifyObj?.customer,
      };

      const res = await fetch(`/.netlify/functions/cartLinesAdd`, {
        method: "POST",
        body: JSON.stringify(data),
      });
      try {
        const response = await res.json();
        if (response.error.length) {
          if (response.error[0].field[0] === "cartId") {
            useStore.setState({
              shopify: {
                ...shopify,
                cart: defaultShopify.cart,
              },
            });
            toast.error(
              "Your previous cart has expired. Please refresh your page and try again."
            );
            setAdding(false);
            return;
          }

          toast.error(
            "Something went wrong when adding the product to cart. Please try again."
          );
          throw new Error(response.error);
        } else {
          useStore.setState({
            shopify: {
              ...shopifyObj,
              cart: response.cart,
            },
          });
          openCartDrawer();
        }
        setAdding(false);
      } catch (err) {
        setAdding(false);
        toast.error(
          "Something went wrong when adding the product to cart. Please try again."
        );
        throw err;
      }
    },
    []
  );

  const { load } = useLoads("handleAddToCart", handleAddToCart);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    const { quantity, variant } = elForm!.current!.elements;
    const lineItems = [
      {
        merchandiseId: "gid://shopify/ProductVariant/" + variant.value,
        quantity: parseInt(quantity.value),
      },
    ];
    load(lineItems, shopify);
  };

  return (
    <Container margin="20px 0 0" tabletMargin="10px 0 0" width="100%">
      <Form ref={elForm} onSubmit={handleSubmit}>
        <Container
          flexWrap="wrap"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          {isButton ? (
            <input type="hidden" name="quantity" value={1} readOnly />
          ) : (
            <QuantityInput
              defaultQuantity={1}
              quantityMax={undefined}
              // {
              //   (shopifyData.quantityMax as number) ??
              //   (shopifyData?.defaultVariant?.inventoryPolicy === "deny" &&
              //     (shopifyData?.defaultVariant.inventoryQuantity as number)) ??
              //   undefined
              // }
            />
          )}

          {shopifyData.defaultPrice && !isButton && (
            <ProductPrice
              price={shopifyData.defaultPrice}
              comparePrice={shopifyData.defaultCompareAtPrice}
            />
          )}
          <input
            type="hidden"
            name="variant"
            value={shopifyData.defaultVariant?.variantId?.toString()}
          />
          <Button
            disabled={adding ? true : outOfStock}
            width="100%"
            margin={isButton ? "0 0 10px" : "30px 0 35px"}
            mobileMargin={isButton ? "0 0 10px" : "24px 0 40px"}
            text={adding ? "ADDING" : outOfStock ? "BACK SOON" : "ADD TO CART"}
            theme="base"
          />
        </Container>
      </Form>
    </Container>
  );
};

export default ProductForm;
